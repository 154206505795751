.counter-container {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 5px;
    align-items: center;
    font-size: 16px;
    padding: 16px 32px;
    font-family: Poppins, serif;


    @media (min-width: 450px) {
        font-size: 20px;
    }

    .counter-btn {
        display: inline-flex;
        border: none;
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
        max-width: 35px;
        max-height: 35px;
        min-width: 35px;
        min-height: 35px;
        font-size: 24px;
        border-radius: 50%;
        font-size: 17px;
        cursor: pointer;
        @media (min-width: 450px) {
            font-size: 20px;
        }
        transition: background 150ms linear;
        background: linear-gradient(123.84deg, #E100FF 11.81%, #8700FF 85.23%);
        color: #FFFFFF;;
        &:hover {
            background: linear-gradient(123.84deg, #E100FF 11.81%, #8700FF 85.23%);;
            opacity: 70;
        }
    }

    span {
        background: linear-gradient(123.84deg, #E100FF 11.81%, #8700FF 85.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: background 100ms linear;
    }
}