.home-page-container {
  background-image: url("../../../public/images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: -8px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .mint-container {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 32px 0;
    max-width: 310px;
    // max-height: 310px;
    box-sizing: border-box;
    border-radius: 15px;
    background: linear-gradient(123.84deg, #e100ff 11.81%, #8700ff 85.23%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 350px) {
      max-width: 340px;
      // max-height: 460px;
    }
    @media (min-width: 450px) {
      max-width: 416px;
      // max-height: 416px;
    }
    @media (min-width: 520px) {
      max-width: 500px;
      // max-height: 500px;
    }
    .mint-logo {
      max-width: 120px;
      @media (min-width: 450px) {
        max-width: 220px;
      }
    }
    .mint-title {
      text-align: center;
      margin-top: 12px;
      font-family: Poppins, serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0;
      padding: 0 8px;
      color: rgba(255, 255, 255, 1);
      @media (min-width: 450px) {
        font-size: 28px;
      }
    }

    .header-image {
      position: absolute;
      top: -100px;

      @media (max-width: 500px) {
        display: none;
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: 8px 0;
    }

    .margin-top {
      margin-top: 16px;
    }
  }
}
