.celestial-button {
  background: #FFFFFF;
  outline: none;
  height: 56px;
  min-width: 200px;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Poppins, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  transition: background 150ms linear;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  @media (min-width: 450px) {
    font-size: 20px;
    min-width: 220px;
  }
  span {
    background: linear-gradient(123.84deg, #E100FF 11.81%, #8700FF 85.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background 100ms linear;
  }

  &:disabled, &[disabled] {
    background: #CCCCCC;
    cursor: not-allowed;
  }

  &:hover {
    //transition: background 1500ms linear;
    background: #CCCCCC;
  }
}