.row-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Poppins, serif;
    font-size: 16px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 16px;

    @media (min-width: 450px) {
        font-size: 20px;
    }

    .label {
        margin-right: 8px;
        background: linear-gradient(123.84deg, #E100FF 11.81%, #8700FF 85.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: background 100ms linear;
    }

    .value {
        display: inline-block;
        overflow: hidden;
        text-overflow:ellipsis;
        // overflow: auto;
        background: linear-gradient(123.84deg, #E100FF 11.81%, #8700FF 85.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: background 100ms linear;
    }
}